import React from "react";
import { motion, useInView } from "framer-motion";
import { Link } from "react-router-dom";
import SectionTitle from "../../../components/SectionTitle";
import { useData } from "../../../services/DataProvider";

const AboutUsSection3 = () => {
  const { data } = useData();

  const buttonRef = React.useRef(null);
  const buttonInView = useInView(buttonRef, {
    once: true,
    margin: "-10% 0px 0% 0px",
  });

  return (
    <section className="py-16 px-2 bg-gray-100">
      <SectionTitle
        title={data.about.content.section3.title}
        description={data.about.content.section3.description}
      />

      <motion.div
        ref={buttonRef}
        className="text-center mb-5"
        initial={{ opacity: 0, y: 20 }}
        animate={{
          opacity: buttonInView ? 1 : 0,
          y: buttonInView ? 0 : 20,
        }}
        transition={{ duration: 0.5, delay: 0.7 }}
      >
        <Link to={`${data.about.content.section3.button1.link}`}>
          <motion.button
            className="px-6 py-3 m-1 bg-sky-500 hover:bg-sky-600 text-white font-semibold rounded-lg shadow-lg transition duration-300 transform hover:scale-105"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            {data.about.content.section3.button1.text}
          </motion.button>
        </Link>

        <Link to={`${data.about.content.section3.button2.link}`}>
          <motion.button
            className="px-6 py-3 m-1 bg-sky-500 hover:bg-sky-600 text-white font-semibold rounded-lg shadow-lg transition duration-300 transform hover:scale-105"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            {data.about.content.section3.button2.text}
          </motion.button>
        </Link>
      </motion.div>
    </section>
  );
};

export default AboutUsSection3;
